<form [formGroup]="projectForm" (ngSubmit)="submitProject()">
  <div class="form-group">
    <label for="projectName">Project Title<span class="ml-1 required">*</span></label>
    <input type="text" class="form-control width-40" formControlName="name" id="projectName" required>
  </div>
  <div class="form-group">
    <label for="department">Department<span class="ml-1 required">*</span></label>
    <select class="form-control width-40" id="department" formControlName="department" required>
      <option *ngFor="let dep of (orgUnits$ |async)" [ngValue]="dep.id">{{dep.name}}</option>
    </select>
  </div>
  <div class="form-group d-flex align-items-center">
    <div class="mr-3 width-40">
      <label for="openDate">Open Date<span class="ml-1 required">*</span></label>
      <input type="date" formControlName="openDate" name="openDate" class="form-control" id="openDate" required>
    </div>
    <div class="ms-2">
      <label for="openTime">Open Time<span class="ml-1 required">*</span></label>
      <input type="time" formControlName="openTime" name="openTime" class="form-control" id="openTime" required>
    </div>
    <div class="ms-2" style="padding-left:.5em; padding-left: 0.5em; 
    padding-top: 1.2em">
      EDT
    </div>
  </div>
  <div class="form-group">
    <div class="d-flex align-items-center">
      <div class="mr-3 width-40">
        <label for="closeDate">Close Date<span class="ml-1 required">*</span></label>
        <input type="date" formControlName="closeDate" name="closeDate" class="form-control" id="closeDate" required>
      </div>
      <div class="ms-2">
        <label for="closeTime">Close Time<span class="ml-1 required">*</span></label>
        <input type="time" formControlName="closeTime" name="closeTime" class="form-control" id="closeTime" required>
      </div>
      <div class="ms-2" style="padding-left:.5em; padding-left: 0.5em; 
      padding-top: 1.2em">
        EDT
      </div>
    </div>
    <div class="error" *ngIf="projectForm?.errors?.dates">{{projectForm.errors?.dates}}<span
        class="ml-1 required">*</span></div>
  </div>

  <div class="form-group">
    <fieldset class="radio">
      <legend>Response Notification Emails</legend>
      <div *ngFor="let cadence of cadenceOptions">
        <input id="id__{{cadence.value}}" type="radio" [value]="cadence.value" name="emailCadence"
          formControlName="emailCadence" />
        <label class="radio-labels" for="id__{{cadence.value}}">{{cadence.label}}</label>
      </div>
    </fieldset>
  </div>

  <div class="form-group">
    <label for="notes">Notes</label>
    <textarea class="form-control width-60" rows="4" formControlName="notes" id="notes"></textarea>
    <div class="error" *ngIf="projectForm?.errors?.notes">{{projectForm.errors?.notes}}<span
      class="ml-1 required">*</span></div>
  </div>
  <div class="required" *ngIf="projectForm.get('notes')?.errors">
      Invalid characters in notes
  </div>

  <div *ngIf="!editProject" class="form-group">
    <label for="genericLink" class="d-flex align-items-center">
      Generic Link
      <span class="mx-1 required">*</span>
      <span placement="right" class="ml-1 gl-popover" tabindex="0" [closeDelay]="500"
        triggers="mouseenter:mouseleave focus:blur"
        ngbPopover="This link should only be used for public access to a project. If mass mailing, a unique link will automatically be generated for each recipient.">
        <i class="bi bi-question-circle-fill d-flex"></i>
      </span>
    </label>
    <div class="d-flex mb-2 align-items-center">
      <div class="input-group genericLink-text width-60">
        <div class="input-group-prepend">
          <div class="input-group-text">https://baseline.campuslabs.com/{{(userSettingsService.currentAccount$ |
            async)?.institution?.urlPrefix}}/</div>
        </div>
        <input type="text" class="form-control" formControlName="genericLink" id="genericLink"
          [class.error]="(isLinkValid$ | async) === false">
      </div>
      <span *ngIf="(isCheckingGenericLink$ | async)" class="pl-1"><i class="bi bi-arrow-clockwise spin"></i></span>
    </div>
    <span class="gl-subtext">Use lowercase, alphanumeric characters only</span>
    <div class="error" *ngIf="(isLinkValid$ | async) === false">Project link is already in use for another project.
    </div>
    <div class="error" *ngIf="projectForm?.errors?.genericLink">{{projectForm.errors?.genericLink}}</div>
  </div>
  <div class="form-group width-60">
    <label>Advanced Options</label>
    <div class="d-flex flex-column">
      <span class="mb-1">
        <input id="hideIdentityInReport" type="checkbox" name="hideIdentityInReport"
          formControlName="hideIdentityInReport" />
        <label class="mb-0 ms-2" for="hideIdentityInReport">Hide User Information in Report</label>
      </span>
      <span class="mb-1">
        <input id="hideResultsInCrossProjectReporting" type="checkbox" name="hideResultsInCrossProjectReporting"
          formControlName="hideResultsInCrossProjectReporting" />
        <label class="mb-0 ms-2" for="hideResultsInCrossProjectReporting">Hide Project Results from Cross-Project
          Reporting</label>
      </span>
      <span class="mb-1">
        <input id="hideQuestionNumbers" type="checkbox" name="hideQuestionNumbers"
          formControlName="hideQuestionNumbers" />
        <label class="mb-0 ms-2" for="hideQuestionNumbers">Hide Question Numbers</label>
      </span>
      <span class="mb-1">
        <input id="hideProgressBar" type="checkbox" name="hideProgressBar" formControlName="hideProgressBar" />
        <label class="mb-0 ms-2" for="hideProgressBar">Hide the Progress Bar</label>
      </span>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <button class="btn btn-link" type="button" (click)="cancel()">Cancel</button>
    <button class="btn btn-primary box-shadow" type="submit"
      [disabled]="!projectForm.valid || (!editProject && (!(isLinkValid$ | async) || (isCheckingGenericLink$ | async)))">Continue</button>
  </div>
</form>
