import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/surveys/src/environments/environment';
import { map, Observable } from 'rxjs';
import { ProjectType } from '../../enums/project-type';
import { IProject, IProjectGoalUpdateRequest, IProjectRequest, IProjectWithGoalsResponse } from '../../models/project';
import { BaseHttpService } from './base-http.service';

@Injectable({ providedIn: 'root' })
export class ProjectHttpService extends BaseHttpService {
  constructor(h: HttpClient) {
    super(h);
  }

  private _buildUrl(append = ''): string {
    return `${environment.BASELINE_API}/surveys/projects/${append}`;
  }

  // We need this function to map Type to the ProjectType enum. The return from api-surveys has Type set
  // to the string 'Form' or 'Goal' depending on the project type
  private mapToProject(data: Observable<any>): Observable<IProject> {
    return data.pipe(map(d => ({ ...d, type: ProjectType[d.type as keyof typeof ProjectType] })));
  }

  // We need this function to map Type to the ProjectType enum. The return from api-surveys has Type set
  // to the string 'Form' or 'Goal' depending on the project type
  private mapToProjectWithGoalsResponse(data: Observable<any>): Observable<IProjectWithGoalsResponse> {
    return data.pipe(map(d => ({ ...d, type: ProjectType[d.type as keyof typeof ProjectType] })));
  }

  createProject(project: IProjectRequest): Observable<IProject> {
    return this.mapToProject(this.postRequest({
      url: this._buildUrl(),
      body: project,
      query: null,
    }));
  }

  updateProject(projectId: string, project: IProjectRequest): Observable<IProject> {
    return this.mapToProject(this.patchRequest({
      url: this._buildUrl(projectId),
      body: project,
      query: null,
    }));
  }

  deleteProjects(organizationId: string, projectIdList: string[]): Observable<boolean> {
    return this.postRequest({
      url: this._buildUrl(`bulk-delete/${organizationId}`),
      body: projectIdList,
      query: null,
    });
  }

  isLinkAvailable(genericLink: string, projectId?: number): Observable<boolean> {
    return this.getRequest({
      url: this._buildUrl('is-link-available'),
      query: { genericLink, projectId },
    });
  }

  getProjectById(projectId: string): Observable<IProject> {
    return this.mapToProject(this.getRequest({
      url: this._buildUrl(projectId),
      query: {},
    }));
  }

  getProjectWithGoals(projectId: string): Observable<IProjectWithGoalsResponse> {
    return this.mapToProjectWithGoalsResponse(this.getRequest({
      url: this._buildUrl(projectId),
      query: {},
    }));
  }

  patchProjectGoals(projectId: number, projectGoalUpdateRequest: IProjectGoalUpdateRequest): Observable<IProjectWithGoalsResponse> {
    return this.mapToProjectWithGoalsResponse(this.patchRequest({
      url: this._buildUrl(`${projectId}/goals`),
      body: projectGoalUpdateRequest,
      query: {},
    }));
  }
}
